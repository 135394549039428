import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit, Signal } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   TooltipDirective,
} from "lim-ui";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import type { TileContent } from "src/app/dashboards/components/customDashboardsPage/custom-dashboard/widget/widgetContent.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import type { VirtualWidgetID } from "src/app/shared/components/global/virutalWidget/virtual-widget.models";
import { VirtualWidgetService } from "src/app/shared/components/global/virutalWidget/virtual-widget.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import type { Lookup } from "src/app/shared/utils/lookup";
import { TaskStatus, TaskType } from "src/app/tasks/components/shared";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "getting-started",
   templateUrl: "./getting-started.component.html",
   styleUrls: ["./getting-started.component.scss"],
   standalone: true,
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      IconComponent,
      TooltipDirective,
      NgClass,
   ],
})
export class GettingStarted implements OnInit, OnDestroy {
   public resolve;
   public modalInstance: LimUiModalRef<GettingStarted, boolean | undefined> =
      inject(LimUiModalRef);
   public title;
   public message;
   public numOfAssets;
   public numOfParts;
   public completedTasksWatchVarSub;
   public source: Array<string> = [];

   private readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly manageParts = inject(ManageParts);
   private readonly credService = inject(CredService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageUser = inject(ManageUser);
   private readonly taskTemplatesApi = inject(TaskTemplatesApiService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageLang = inject(ManageLang);
   private readonly virtualWidgetService = inject(VirtualWidgetService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly virtualWidgetIds: VirtualWidgetID[] = [
      "tasksCompleted",
      "plannedMaintenance",
      "workRequests",
   ];
   private readonly widgetResponse$ = this.virtualWidgetService.fetchWidgetContents({
      widgetIds: this.virtualWidgetIds,
      filters: {
         taskStatuses: [TaskStatus.Closed],
         taskTypes: [
            TaskType.CycleCounts,
            TaskType.MaterialRequests,
            TaskType.PartThresholds,
            TaskType.PlannedMaintenances,
            TaskType.PlannedWorkOrders,
            TaskType.UnplannedWorkOrders,
            TaskType.WorkRequests,
         ],
      },
   });
   public widgetContents = toSignal(this.widgetResponse$);

   public numWorkRequests: Signal<number> = computed(() => {
      const widgetIndex = this.virtualWidgetIds.indexOf("workRequests");
      const content = this.widgetContents()?.[widgetIndex];
      return (content as TileContent)?.value ?? 0;
   });
   public numPlannedMaintenance: Signal<number> = computed(() => {
      const widgetIndex = this.virtualWidgetIds.indexOf("plannedMaintenance");
      const content = this.widgetContents()?.[widgetIndex];
      return (content as TileContent)?.value ?? 0;
   });
   public numTasksCompleted: Signal<number> = computed(() => {
      const widgetIndex = this.virtualWidgetIds.indexOf("tasksCompleted");
      const content = this.widgetContents()?.[widgetIndex];
      return (content as TileContent)?.value ?? 0;
   });

   public ngOnInit() {
      this.title = this.lang().GettingStartedWithLimble;
      this.message = this.lang().GettingStartedWithLimbleMsg;

      setTimeout(() => {
         (() => {
            const youtube = Array.from(
               document.querySelectorAll<HTMLElement>(".youtube"),
            );

            for (const youtubeSection of youtube) {
               const source = `https://img.youtube.com/vi/${youtubeSection.dataset.embed}/sddefault.jpg`;
               this.source.push(source);
            }
         })();
      }, 1);

      this.completedTasksWatchVarSub = this.manageObservables.setSubscription(
         "completedTasksWatchVar",
         () => {
            const locationsObj = {};
            let allLoc = false;
            let locations: Array<number> = [];
            const profileLoc = this.manageUser.getCurrentUser().profileLoc;
            for (const loc of profileLoc) {
               if (loc.locationID == 0) {
                  allLoc = true;
                  locations = this.manageLocation
                     .getLocations()
                     .map((location) => location.locationID);
               } else if (
                  this.credService.isAuthorized(
                     loc.locationID,
                     this.credService.Permissions.ViewAllOpenTasks,
                  ) ||
                  this.credService.isAuthorized(
                     loc.locationID,
                     this.credService.Permissions.ViewManagePMs,
                  ) ||
                  this.credService.isAuthorized(
                     loc.locationID,
                     this.credService.Permissions.ViewManageAssets,
                  ) ||
                  this.credService.isAuthorized(
                     loc.locationID,
                     this.credService.Permissions.ViewManageParts,
                  ) ||
                  this.credService.isAuthorized(
                     loc.locationID,
                     this.credService.Permissions.ViewManageUsers,
                  )
               ) {
                  //pulling metrics for how much is completed
                  locationsObj[loc.locationID] = loc.locationID;
               }
            }

            for (const key in locationsObj) {
               locations.push(locationsObj[key]);
            }

            let assets = this.manageAsset.getAssets();

            if (!allLoc) {
               assets = assets.filter((asset) => locations.includes(asset.locationID));
            }

            assets = assets.filter((asset) => asset.assetDeleted === 0);

            this.numOfAssets = assets.size;

            this.numOfParts = 0;
            let parts: Lookup<"partID", Part> = this.manageParts.getParts();
            if (!allLoc) {
               parts = this.manageFilters.filterLookupByLocationIDs(parts, locations);
            }

            for (const part of parts) {
               if (part.partDeleted == 0) {
                  this.numOfParts++;
               }
            }
         },
      );
   }

   public linkToVideo(title, embed) {
      this.manageUtil.watchVideo({
         title: title,
         src: `https://www.youtube.com/embed/${embed}?autoplay=1&rel=0`,
      });
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.completedTasksWatchVarSub);
   }

   close = () => {
      this.modalInstance.close();
   };
}
