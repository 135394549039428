<lim-ui-modal [showWatermark]="true">
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span class="message-area" [limbleHtml]="message"></span>
      </lim-ui-info-panel>

      <lim-ui-panel>
         <div class="information-row">
            <div class="information-column">
               <!--start of link 1-->
               <span class="img-title">
                  <b class="using-mobile-app">{{ lang().LearnToNavigateTheApp }}</b>
               </span>
               <div class="wrapper">
                  <div
                     class="youtube youtubeHolder"
                     [attr.data-embed]="lang().LearnToNavigateTheAppTutorial"
                     data-title="{{ lang().LearnToNavigateTheAppTooltip }}"
                     (click)="
                        linkToVideo(
                           lang().LearnToNavigateTheApp,
                           lang().LearnToNavigateTheAppTutorial
                        )
                     "
                  >
                     <img class="img-wrapper" [src]="source[0]" />
                     <lim-ui-icon
                        class="float-over-image"
                        iconColor="danger"
                        iconSize="extra-large"
                        icon="circlePlay"
                     />
                  </div>
               </div>
               <!--end of link 1-->
               <!--start of link 4-->
               <span class="img-title">
                  <b>{{ lang().GettingStartedSettingUpFirstPM }}</b>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().GettingStartedSettingUpFirstPMTooltip }}"
                     placement="left"
                  />
               </span>
               <span class="completed-tasks">
                  <span
                     class="match-font"
                     [ngClass]="{
                        redColor: numPlannedMaintenance() < 5,
                        greenColor: numPlannedMaintenance() >= 5,
                     }"
                     >{{ numPlannedMaintenance() }}/5</span
                  >
                  {{ lang().GettingStartedSettingUpFirstPMPMSetup }}
               </span>
               <div class="wrapper bottom-row">
                  <div
                     class="youtube youtubeHolder"
                     [attr.data-embed]="lang().GettingStartedSettingUpFirstPMTutorial"
                     data-title="{{
                        lang().GettingStartedSettingUpFirstPMTutorialHeader
                     }}"
                     (click)="
                        linkToVideo(
                           lang().GettingStartedSettingUpFirstPMTutorialHeader,
                           lang().GettingStartedSettingUpFirstPMTutorial
                        )
                     "
                  >
                     <img class="img-wrapper" [src]="source[1]" />
                     <lim-ui-icon
                        class="float-over-image"
                        iconColor="danger"
                        iconSize="extra-large"
                        icon="circlePlay"
                     />
                  </div>
               </div>
               <!--end of link 4-->
               <!--start of link 7-->
               <span class="img-title third-row-margin">
                  <b>{{ lang().GettingStartedUsingParts }}</b>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().GettingStartedUsingPartsTooltip }}"
                     placement="left"
                  />
               </span>
               <span class="completed-tasks">
                  <span
                     class="match-font"
                     [ngClass]="{
                        redColor: numOfParts < 5,
                        greenColor: numOfParts >= 5,
                     }"
                     >{{ numOfParts }}/5</span
                  >
                  {{ lang().GettingStartedUsingPartsPartsAdded }}
               </span>
               <div class="wrapper bottom-row third-row-bottom">
                  <div
                     class="youtube youtubeHolder"
                     [attr.data-embed]="lang().GettingStartedUsingPartsTutorial"
                     data-title="{{ lang().GettingStartedUsingPartsTutorialHeader }}"
                     (click)="
                        linkToVideo(
                           lang().GettingStartedUsingPartsTutorialHeader,
                           lang().GettingStartedUsingPartsTutorial
                        )
                     "
                  >
                     <img class="img-wrapper" [src]="source[2]" />
                     <lim-ui-icon
                        class="float-over-image"
                        iconColor="danger"
                        iconSize="extra-large"
                        icon="circlePlay"
                     />
                  </div>
               </div>
               <!--end of link 7-->
            </div>
            <div class="information-column">
               <!--start of link 2-->
               <span class="img-title">
                  <b>{{ lang().StartManagingWork }}</b>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().GettingStartedTasksCompletedTooltip }}"
                     data-placement="left"
                  />
               </span>
               <span class="completed-tasks">
                  <span
                     class="match-font"
                     [ngClass]="{
                        redColor: numTasksCompleted() < 20,
                        greenColor: numTasksCompleted() >= 20,
                     }"
                     >{{ numTasksCompleted() }}/20</span
                  >
                  {{ lang().GettingStartedTasksCompleted }}
               </span>
               <div class="wrapper">
                  <div
                     class="youtube youtubeHolder"
                     [attr.data-embed]="'Da_kL16Xjac'"
                     data-title="{{
                        lang().GettingStartedStartManagingWorkTutorialHeading
                     }}"
                     (click)="
                        linkToVideo(
                           lang().GettingStartedStartManagingWorkTutorialHeading,
                           'Da_kL16Xjac'
                        )
                     "
                  >
                     <img class="img-wrapper" [src]="source[3]" />

                     <lim-ui-icon
                        class="float-over-image"
                        iconColor="danger"
                        iconSize="extra-large"
                        icon="circlePlay"
                     />
                  </div>
               </div>
               <!--end of  link 2-->
               <!--start of link 5-->
               <span class="img-title">
                  <b class="using-mobile-app">{{
                     lang().GettingStartedUsingMobileApp
                  }}</b>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().GettingStartedUsingMobileAppTooltip }}"
                     placement="left"
                  />
               </span>
               <div class="wrapper bottom-row">
                  <div
                     class="youtube youtubeHolder"
                     [attr.data-embed]="lang().GettingStartedUsingMobileAppTutorial"
                     data-title="{{ lang().GettingStartedUsingMobileAppTutorialHeader }}"
                     (click)="
                        linkToVideo(
                           lang().GettingStartedUsingMobileAppTutorialHeader,
                           lang().GettingStartedUsingMobileAppTutorial
                        )
                     "
                  >
                     <img class="img-wrapper" [src]="source[4]" />
                     <lim-ui-icon
                        class="float-over-image"
                        iconColor="danger"
                        iconSize="extra-large"
                        icon="circlePlay"
                     />
                  </div>
               </div>
               <!--end of link 5-->
            </div>
            <div class="information-column">
               <!--start of link 3-->
               <span class="img-title">
                  <b>{{ lang().GettingStartedStartAddingAssets }}</b>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().GettingStartedStartAddingAssetsTooltip }}"
                     placement="left"
                  />
               </span>
               <span class="completed-tasks">
                  <span
                     class="match-font"
                     [ngClass]="{
                        redColor: numOfAssets < 10,
                        greenColor: numOfAssets >= 10,
                     }"
                     >{{ numOfAssets }}/10</span
                  >
                  {{ lang().GettingStartedStartAddingAssetsAssetsAdded }}
               </span>
               <div class="wrapper">
                  <div
                     class="youtube youtubeHolder"
                     [attr.data-embed]="lang().GettingStartedStartAddingAssetsTutorial"
                     data-title="{{
                        lang().GettingStartedStartAddingAssetsTutorialHeader
                     }}"
                     (click)="
                        linkToVideo(
                           lang().GettingStartedStartAddingAssetsTutorialHeader,
                           lang().GettingStartedStartAddingAssetsTutorial
                        )
                     "
                  >
                     <img class="img-wrapper" [src]="source[5]" />
                     <lim-ui-icon
                        class="float-over-image"
                        iconColor="danger"
                        iconSize="extra-large"
                        icon="circlePlay"
                     />
                  </div>
               </div>
               <!--end of link 3-->
               <!--start of link 6-->
               <span class="img-title">
                  <b>{{ lang().GettingStartedUserTheWorkRequest }}</b>
                  <lim-ui-icon
                     icon="circleQuestionRegular"
                     limUiTooltip="{{ lang().GettingStartedUserTheWorkRequestTooltip }}"
                     placement="left"
                  />
               </span>
               <span class="completed-tasks">
                  <span
                     class="match-font"
                     [ngClass]="{
                        redColor: numWorkRequests() < 5,
                        greenColor: numWorkRequests() >= 5,
                     }"
                     >{{ numWorkRequests() }}/5</span
                  >
                  {{ lang().GettingStartedUserTheWorkRequestWorkRequests }}
               </span>
               <div class="wrapper bottom-row">
                  <div
                     class="youtube youtubeHolder"
                     [attr.data-embed]="lang().GettingStartedUserTheWorkRequestTutorial"
                     data-title="{{
                        lang().GettingStartedUserTheWorkRequestTutorialHeader
                     }}"
                     (click)="
                        linkToVideo(
                           lang().GettingStartedUserTheWorkRequestTutorialHeader,
                           lang().GettingStartedUserTheWorkRequestTutorial
                        )
                     "
                  >
                     <img class="img-wrapper" [src]="source[6]" />
                     <lim-ui-icon
                        class="float-over-image"
                        iconColor="danger"
                        iconSize="extra-large"
                        icon="circlePlay"
                     />
                  </div>
               </div>
               <!--end of link 6-->
            </div>
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>
