import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   IconComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   PanelComponent,
   TooltipDirective,
} from "lim-ui";
import { lastValueFrom } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { ManageParts } from "src/app/parts/services/manageParts";
import type { Part } from "src/app/parts/types/part.types";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import type { Lookup } from "src/app/shared/utils/lookup";
import { TaskTemplatesApiService } from "src/app/tasks/components/shared/services/task-templates-api/task-templates-api.service";
import { ManageTask } from "src/app/tasks/services/manageTask";
import type { TaskLookup } from "src/app/tasks/types/task.types";
import { CredService } from "src/app/users/services/creds/cred.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "getting-started-legacy",
   templateUrl: "./getting-started-legacy.modal.component.html",
   styleUrls: ["./getting-started-legacy.modal.component.scss"],
   standalone: true,
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      PanelComponent,
      IconComponent,
      TooltipDirective,
      NgClass,
   ],
})
export class GettingStartedLegacy implements OnInit, OnDestroy {
   public resolve;
   public modalInstance: LimUiModalRef<GettingStartedLegacy, boolean | undefined> =
      inject(LimUiModalRef);
   public title;
   public message;
   public numOfAssets;
   public numOfCompletedTasks;
   public numOfCompletedWRs;
   public numOfPMs;
   public numOfParts;
   public completedTasksWatchVarSub;
   public source: Array<string> = [];

   private readonly manageAsset = inject(ManageAsset);
   private readonly manageTask = inject(ManageTask);
   private readonly manageParts = inject(ManageParts);
   private readonly credService = inject(CredService);
   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUtil = inject(ManageUtil);
   private readonly manageUser = inject(ManageUser);
   private readonly taskTemplatesApi = inject(TaskTemplatesApiService);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.title = this.lang().GettingStartedWithLimble;
      this.message = this.lang().GettingStartedWithLimbleMsg;

      setTimeout(() => {
         (() => {
            const youtube = Array.from(
               document.querySelectorAll<HTMLElement>(".youtube"),
            );

            for (const youtubeSection of youtube) {
               const source = `https://img.youtube.com/vi/${youtubeSection.dataset.embed}/sddefault.jpg`;
               this.source.push(source);
            }
         })();
      }, 1);

      this.completedTasksWatchVarSub = this.manageObservables.setSubscription(
         "completedTasksWatchVar",
         async () => {
            const locationsObj = {};
            let allLoc = false;
            let locations: Array<number> = [];
            const profileLoc = this.manageUser.getCurrentUser().profileLoc;
            for (const loc of profileLoc) {
               if (loc.locationID == 0) {
                  allLoc = true;
                  locations = this.manageLocation
                     .getLocations()
                     .map((location) => location.locationID);
               } else if (
                  this.credService.isAuthorized(
                     loc.locationID,
                     this.credService.Permissions.ViewAllOpenTasks,
                  ) ||
                  this.credService.isAuthorized(
                     loc.locationID,
                     this.credService.Permissions.ViewManagePMs,
                  ) ||
                  this.credService.isAuthorized(
                     loc.locationID,
                     this.credService.Permissions.ViewManageAssets,
                  ) ||
                  this.credService.isAuthorized(
                     loc.locationID,
                     this.credService.Permissions.ViewManageParts,
                  ) ||
                  this.credService.isAuthorized(
                     loc.locationID,
                     this.credService.Permissions.ViewManageUsers,
                  )
               ) {
                  //pulling metrics for how much is completed
                  locationsObj[loc.locationID] = loc.locationID;
               }
            }

            for (const key in locationsObj) {
               locations.push(locationsObj[key]);
            }

            let assets = this.manageAsset.getAssets();

            if (!allLoc) {
               assets = assets.filter((asset) => locations.includes(asset.locationID));
            }

            assets = assets.filter((asset) => asset.assetDeleted === 0);

            this.numOfAssets = assets.size;

            let completedTasks: TaskLookup = this.manageTask.getCompletedTasks();

            if (!allLoc) {
               completedTasks = this.manageFilters.filterLookupByLocationIDs(
                  completedTasks,
                  locations,
               );
            }

            this.numOfCompletedTasks = 0;
            this.numOfCompletedWRs = 0;

            for (const task of completedTasks) {
               if (task?.checklistTemplateOld && task.checklistTemplateOld > 0) {
                  this.numOfCompletedTasks++;
                  if (task.checklistBatchID == 10000 || task.checklistBatchID == 300112) {
                     this.numOfCompletedWRs++;
                  }
               }
            }

            this.numOfPMs = (
               await lastValueFrom(
                  this.taskTemplatesApi.getList({
                     params: { locationIDs: locations, checklistTemplates: [1] },
                  }),
               )
            ).total;

            this.numOfParts = 0;
            let parts: Lookup<"partID", Part> = this.manageParts.getParts();
            if (!allLoc) {
               parts = this.manageFilters.filterLookupByLocationIDs(parts, locations);
            }

            for (const part of parts) {
               if (part.partDeleted == 0) {
                  this.numOfParts++;
               }
            }
         },
      );
   }

   public linkToVideo(title, embed) {
      this.manageUtil.watchVideo({
         title: title,
         src: `https://www.youtube.com/embed/${embed}?autoplay=1&rel=0`,
      });
   }

   public ngOnDestroy() {
      this.manageObservables.removeSubscription(this.completedTasksWatchVarSub);
   }

   close = () => {
      this.modalInstance.close();
   };
}
